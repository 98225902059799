import React from "react";
import HeaderComponent from "../Header/HeaderComponent";
import FooterComponent from "../Footer/FooterComponent";
const ReviewEditorsComponent = () => {
    const teamData = [
        {
            name: "Dr. Ishmeet Kaur Chaudhry",
            position: "Assistant Professor",
            department: "Department of English Studies",
            university: "Central University of Gujarat",
            location: "Gujarat, India",
            imageSrc: "./img/editors/ishmeet.jpg",
        },
        {
            name: "Dr. Asis De",
            position: "Associate Professor of English and Director of Research",
            department: "(Humanities and Social Sciences)",
            university: "Mahishadal Raj College, Vidyasagar University",
            location: "West Bengal, India",
            imageSrc: "./img/editors/asis.jpg",
        },
        {
            name: "Dr. Sajaudeen Chapparban",
            position: "Assistant Professor",
            department: "Department of Diaspora Studies",
            university: "Central University of Gujarat",
            location: "Gandhinagar, Gujarat, India",
            imageSrc: "./img/editors/sajaudeen.jpg",
        },
        {
            name: "Dr. Saroj Kumar",
            position: "Assistant Professor",
            department: "Department of English Language and Applied Linguistics",
            university: "Central University of South Bihar",
            location: "Gaya, Bihar, India",
            imageSrc: "./img/editors/saroj.jpg",
        },
        {
            name: "Dr. Ved Prakash",
            position: "Assistant Professor",
            department: "Department of English Studies",
            university: "Central University of Rajasthan",
            location: "Ajmer, Rajasthan, India",
            imageSrc: "./img/editors/ved.jpg",
        },
        {
            name: "Dr. Manjinder Kaur Wratch",
            position: "Assistant Professor",
            department: "Department of English and Communication Skills",
            university: "UIPS Panjab University",
            location: "Chandigarh, India",
            imageSrc: "./img/editors/manjinder.jpg",
        },
        {
            name: "Dr. Nitin Malhotra",
            position: "Assistant Professor",
            department: "Department of English",
            university: "Amity School of Languages, Amity University",
            location: "Madhya Pradesh, India",
            imageSrc: "./img/editors/nitin.jpg",
        },
        {
            name: "Dr. Vishnu Patil",
            position: "Associate Professor",
            department: "Department of English Language",
            university: "Deogiri College",
            location: "Chhatrapati, Sambhaji Nagar, Maharashtra, India",
            imageSrc: "./img/editors/vishnu.jpg",
        },
        {
            name: "Dr. Sumit Garg",
            position: "Assistant Professor",
            department: "Department of English",
            university: "Punjabi University",
            location: "Patiala, Punjab, India",
            imageSrc: "./img/editors/sumit.jpeg",
        },
        {
            name: "Dr. Amandeep Rana",
            position: "Assistant Professor",
            department: "JC DAV College",
            university: "Dasuya, Punjab, India",
            location: "Punjab, India",
            imageSrc: "./img/editors/mandeep.jpg",
        },
        {
            name: "Dr. Dharmendra Kumar",
            position: "Assistant Professor",
            department: "Department of English",
            university: "H.N.B Garhwal University",
            location: "Srinagar, Uttarakhand, India",
            imageSrc: "./img/editors/dharmendar.jpg",
        },
        {
            name: "Dr. Narinder K. Sharma",
            position: "Assistant Professor of English",
            department: "Department of English",
            university: "Central University of Punjab",
            location: "Bathinda, India",
            imageSrc: "./img/editors/Dr. Narinder.jpeg",
        },
        {
            name: "Dr. Sumneet Kaur",
            position: "Assistant Professor",
            department: "Department of English Literature",
            university: "University of Delhi",
            location: "Delhi, India",
            imageSrc: "./img/editors/sumneet.jpg",
        },
        {
            name: "Dr. Ankit Trivedi",
            position: "Assistant Professor",
            department: "Department of English and Modern European and other Foreign Languages",
            university: "CSJM University",
            location: "Kanpur, Uttar Pradesh, India",
            imageSrc: "./img/editors/ankit.png",
        },
        {
            name: "Dr. Vandana Rajoriya",
            position: "Assistant Professor",
            department: "Department of English",
            university: "Government College, Guna",
            location: "Madhya Pradesh, India",
            imageSrc: "./img/editors/vandana.jpg",
        },
        {
            name: "Dr. S Boopathi",
            position: "Assistant Professor",
            department: "Department of English",
            university: "Periyar University",
            location: "Salem, Tamil Nadu, India",
            imageSrc: "./img/editors/bopathi.jpg",
        },
        {
            name: "Dr. Upendra Gundala",
            position: "Assistant Professor",
            department: "Centre for Applied Linguistics and Translation Studies",
            university: "University of Hyderabad",
            location: "Hyderabad, India",
            imageSrc: "./img/editors/upendra.jpg",
        },
        {
            name: "Dr. Braj Mohan Mishra",
            position: "Assistant Professor",
            department: "The English and Foreign Languages University",
            university: "Regional Campus Lucknow",
            location: "Uttar Pradesh, India",
            imageSrc: "./img/editors/braj.jpeg",
        },
        {
            name: "Dr. Mohd Sajid Ansari",
            position: "Assistant Professor",
            department: "Department of English",
            university: "GSH (PG) College Chandpur-Bijnor",
            location: "MJP Rohilkhand University, Bareilly U.P.",
            imageSrc: "./img/editors/sajid.jpg",
        },
    ]
    
  return (
    <>
      <HeaderComponent activeLink={"editorial"} />

      <div className="container-fluid page-header mb-5 py-5">
        <div className="container">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Review Editors
          </h1>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div
          className="container"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="row g-4" style={{ display: "flex" }}>
            {teamData.map((member, index) => (
              <div
                key={index}
                className={`col-lg-${index < 1000 ? 3 : 2} col-md-6 wow fadeInUp showGrid`}
                data-wow-delay="0.1s"
                style={index >= 1000 ? { flex: '0 0 20%' } : {}}
              >
                <div className="team-item list">
                  <div className="position-relative overflow-hidden">
                    <img
                      className="img-fluid imageHeightWidth"
                      src={member.imageSrc}
                      alt={`pic`}
                    />
                  </div>
                  <div className="team-text new-team">
                    <div className="bg-light-new">
                      <h5 className="fw-bold mb-0">{member.name}</h5>

                      <h6
                        key={index}
                        style={{
                          color: "rgb(72, 111, 176)",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                        }}
                        className="fw-bold mb-0"
                      >
                        {member.department}
                        <br />
                        {member.university}
                        <br />
                        {member.location}
                        {
                            member?.extra && <><br/>{member.extra}</> 
                        }
                      </h6>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ))}
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default ReviewEditorsComponent;

import React, { useEffect, useState } from "react";
import "./Home.css";
import HeaderComponent from "../Header/HeaderComponent";
import FooterComponent from "../Footer/FooterComponent";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import CampaignIcon from '@mui/icons-material/Campaign';

const HomeComponent = () => {
    const [spinner, setSpinner] = useState(true);
    const [showModal, setShowModal] = useState(true);
    const navigate = useNavigate();

    const style = {
      position: 'relative',
      top: '30%',
      left: '20%',
      width: "50%",
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    useEffect(()=>{
        setTimeout(()=>{
            setSpinner(false)
        },1000)
    },[])

    const handleButtonClick = (e) => {
      e.preventDefault();
      const link = document.createElement("a");
      link.href = `${process.env.PUBLIC_URL}/img/call_for_papers.png`;
      link.download = "call_for_papers.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      navigate('/callForPapers');
    }

    const handleClose = () => {
      setShowModal(false)
    }


  return (
    <>
      {spinner && (
        <div
          id="spinner"
          className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
        >
          <div
            className="spinner-border text-primary"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <HeaderComponent activeLink={"home"}/>
      {
        showModal &&
        <div>
          <Modal
            keepMounted
            open={showModal}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box sx={style}>
              <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                <CampaignIcon/> ANOUNCEMENT 
                <button className="btn btn-primary closeBtn" onClick={handleClose} type="button">
                  X
                </button>
              </Typography>
              <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
              LitCult is pleased to announce its call for papers for Vol 01 No 02, scheduled for publication in July 2025.
              <br />For further details &nbsp;
                <a href="/callForPapers" onClick={(e) => handleButtonClick(e)} type="button">
                  click here
                </a>
              </Typography>
            </Box>
          </Modal>
        </div>
      }
      <div className="container-fluid p-0 mb-5">
        <div className="owl-carousel header-carousel position-relative">
          <div className="owl-carousel-item position-relative">
            <img className="img-fluid" src="./img/book2.jpg" alt="" />
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
              style={{ background: "rgba(0, 0, 0, .4)" }}
            >
              <div className="container">
                <div className="row justifyContent-start">
                  <div className="col-10 col-lg-8">
                    <h1 className="display-3 text-white animated slideInDown mb-4">
                      LitCult
                    </h1>
                    <h5 className="text-white mb-3 text-uppercase slideInDown">
                      A Journal of Literary and Cultural Discourse
                    </h5>
                    <p className="fs-5 fw-medium text-white mb-4 pb-2">
                      A Bi-annual Peer Reviewed Open Access E-Journal
                    </p>
                    <a
                      href="/readmore"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div
          className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s"></div>
          <h1 className="mb-2">From the Desk of the Editor</h1>
        </div>
        <div
          className="row g-4"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "20px",
          }}
        >
          <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="team-item">
              <div className="position-relative overflow-hidden">
                <img className="img-fluid" src="./img/PicCult.png" alt="" />
              </div>
              <div className="team-text">
                <div className="bg-light">
                  <h5 className="fw-bold mb-0">Dr. Kaptan Singh</h5>
                </div>
                <div className="bg-primary">
                  <a className="btn btn-square mx-1" href="/">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    className="btn btn-sm-square me-1"
                    href="/"
                    style={{
                      borderRadius: "50%",
                      padding: "25px",
                      marginLeft: "10px",
                    }}
                  >
                    <img src="./img/scholar.png" alt="img" />
                  </a>{" "}
                  &nbsp;
                  <a className="btn btn-square mx-1" href="/">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-5">
          <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
            <p
              className="mb-4"
              style={{
                margin: "20px 60px",
                textAlign: "justify",
              }}
            >
              “Opinions, opinions, opinions abound - I do believe that this,
              here, opinion is sound.”
              <br />
              ― A.K. Kuykendall
              <br />
              <br />
              Dear Scholars,
              <br />
              Embarking on the journey to establish a journal that delves into
              the intricate tapestry of literature and culture has been a
              lifelong aspiration. As a dedicated literary scholar, our
              conviction is unwavering in recognizing the profound influence
              that culture exerts on the human experience across epochs. We are
              driven by the belief that the intersection of literature and
              culture serves as a gateway to understanding the rich and complex
              threads that weave through the fabric of human existence. LitCult:
              A Journal of Literary and Cultural Discourse, an embodiment of
              our enduring dream, aims to unravel the narratives that bind us to
              the ever-evolving tapestry of our collective heritage.
              <br />
              The inaugural edition of a journal marks a pivotal moment for any
              editor—a culmination of dedicated efforts, aspirations, and the
              embodiment of a vision. It signifies not only the birth of a
              publication but the initiation of a dialogue, a literary and
              cultural exploration that is set to unfold on the pages of
              LitCult.
              <br />
              In this moment of introduction, I find myself immersed in a dual
              sentiment—pleasure and anticipation. Pleasure, derived from the
              joyous realization of bringing forth a platform that endeavors to
              delve into the depths of literature and culture. Anticipation, as
              we eagerly await the responses, interpretations, and connections
              this inaugural edition will forge among our readers.
              <br />
              LitCult, in its infancy, is a testament to the belief in the
              transformative power of words and the impact they hold in shaping
              our understanding of the world. It is an endeavor to foster a
              community of literary and cultural enthusiasts, intellectuals and
              thinkers, and curators and explorers who, through the pages of
              this journal, will embark on a collective journey of discovery.
              <br />
              Introducing the inaugural edition of LitCult, I extend a warm
              invitation for you to enrich the pages of this literary endeavor
              with your scholarly papers and articles. Your contribution will
              undoubtedly make this first edition a catalyst for intellectual
              exploration and a celebration of the intricate connection between
              the written word and the diverse landscapes of culture.
              <br />
              Here's to the beginning of a literary odyssey. Happy browsing!
              <br />
              <br />
              Warm regards,
              <br />
              Dr. Kaptan Singh
            </p>
          </div>
        </div>
      </div>
      <FooterComponent/>
    </>
  );
};

export default HomeComponent;
